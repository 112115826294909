import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import useStore from '../store/useStore'
import SEO from '../layouts/Seo'
import BankaCard from '../components/cards/BankaCard'
import CustomAlert from '../components/Alerts/CustomAlert'

const BankaBilgileriPage = () => {
  const { getImageByName } = useStore()
  return (
    <>
      <SEO
        title="Banka Bilgilerimiz - Erez Kaynak"
        description="Banka Bilgilerimiz EREZ KAYNAK Uğur Erkin DEMİRKAYA İkitelli Org.San.Bölgesi Dersan Koop San.Sit. S6 C Blok No:103 Başakşehir / İSTANBULİkitelli VD. V.No: 31198823454Mersis No: 3119882345400001Ticaret Sicil No: 102885-5 Banka BilgilerimizGaranti Bankası : Hesap adı: Uğur Erkin DEMİRKAYAŞube : 1440 Hesap No:"
        keywords="hasır tel,hasır tel fiyatları,hasır tel imalatı,hasır tel kullanım alanları,kafes tel kullanım alanları,tel kafes kullanım alanları,paslanmaz hasır tel,hasır telleri,puntalı hasır tel,punta kaynaklı hasır tel,puntalı hasır tel fiyatları,hasır,tel,hasır tel imalatçısı,özel hasır tel,galvaniz hasır tel,hasır tel istanbul,tel hasır,kafes tel,tel hasır fiyatları,kafes tel fiyatları,hasır tel fiyatı"
        url="banka-bilgileri"
      />
      <Box paddingX={3} paddingY={5}>
        <Box mb={3}>
          <CustomAlert text="11.03.2021 Tarihinden itibaren hesap bilgilerimiz değişmiştir. Lütfen yapacağınız işlemlerde aşağıdaki yeni bilgileri kullanınız." />
        </Box>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <BankaCard
              cardImage={getImageByName('garanti').original.src}
              bankTitle="Garanti Bankası"
              hesapAdi="Uğur Erkin DEMİRKAYA"
              sube="1440"
              hesapNo="6297862"
              iban="TR42 0006 2001 4400 0006 2978 62"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <BankaCard
              cardImage={getImageByName('ziraat').original.src}
              bankTitle="Ziraat Bankası"
              hesapAdi="Uğur Erkin DEMİRKAYA"
              sube="2406"
              hesapNo="561942675006"
              iban="TR05 0001 0024 0656 1942 6750 06"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <BankaCard
              cardImage={getImageByName('yapikredi').original.src}
              bankTitle="YapıKredi Bankası"
              hesapAdi="Uğur Erkin DEMİRKAYA"
              sube="1250"
              hesapNo="20443286"
              iban="TR08 0006 7010 0000 0020 4432 86"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
export default BankaBilgileriPage
